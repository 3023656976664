import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Contact from './Contact';
import noTransactionIcon from '../../assets/bankrupt.png';
import { BASE_URL_SERVER, ERROR_CODES } from '../../constant';
import snackBarAction from '../Error/SnackBarAction';

const ContactList = ({
  token,
  userId,
  handleShowAddContactForm,
  enqueueSnackbar,
  navigate
}) => {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [contacts, setContacts] = useState([]);
  const loadContacts = useCallback(async () => {
    try {
      const { data } = await axios.get(`${BASE_URL_SERVER}/customers/${userId}/contacts`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setContacts(data);
    } catch (error) {
      if (error.response.status === ERROR_CODES.UNREACHABLE) {
        enqueueSnackbar('Server unreachable', {
          persist: true,
          action: snackBarAction,
          preventDuplicate: true
        });
        return;
      }
      enqueueSnackbar('Unable to load contacts', { autoHideDuration: 2500 });
    }
  }, [token, userId, enqueueSnackbar]);

  const handleFilterChange = (searchKey, contactList) => {
    let filteredContacts = contactList;
    const EMPTY_STRING = '';
    if (searchKey.trim() !== EMPTY_STRING) {
      filteredContacts = contactList.filter((contact) => {
        const name = contact.name.toLowerCase();
        const cashTag = contact.cashTag.toLowerCase();
        const NOT_FOUND_INDEX = -1;
        return name.indexOf(searchKey.toLowerCase()) !== NOT_FOUND_INDEX
                    || cashTag.indexOf(searchKey.toLowerCase()) !== NOT_FOUND_INDEX;
      });
    }
    return filteredContacts;
  };

  useEffect(() => {
    loadContacts();
  }, [loadContacts]);
  return (
    <>
      <CssBaseline />
      <Container
        maxWidth="sm"
        data-testid="contacts-list"
        sx={{
          pt: {
            xs: 2,
            md: 2
          },
          overflow: 'auto',
          height: '100%'
        }}
      >
        <Paper
          component="form"
          elevation={0}
          sx={{
            p: '2px 4px', display: 'flex', alignItems: 'center'
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Contact ..."
            inputProps={{ 'aria-label': 'search contact' }}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
          <SearchIcon type="submit" sx={{ mr: 1.5 }} color="primary" aria-label="search" />
        </Paper>
        {contacts.length === 0
          ? (
            <Box sx={{
              width: '100%',
              height: '85vh',
              pt: {
                xs: 2,
                md: 2
              },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            >
              <img src={noTransactionIcon} alt="no transaction" />
              <Typography>No contact records found</Typography>
            </Box>
          ) : (
            <List sx={{
              width: '100%',
              mb: 10,
              overflow: 'scroll'
            }}
            >
              {handleFilterChange(search, contacts).map((contact) => (
                <Contact
                  navigate={navigate}
                  key={contact.id}
                  contact={contact}
                />
              ))}
            </List>
          )}
      </Container>
      <Container
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          padding: 0,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}
        maxWidth="sm"
      >
        <Fab
          color="primary"
          aria-label="add"
          onClick={handleShowAddContactForm}
          sx={{
            [theme.breakpoints.up('sm')]: {
              bottom: 80
            },
            bottom: 75,
            mr: 1
          }}
          variant="extended"
        >
          <AddIcon />
          Add Contact
        </Fab>
      </Container>
    </>
  );
};
ContactList.propTypes = {
  userId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  handleShowAddContactForm: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired
};
export default ContactList;
