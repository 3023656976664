import Button from '@mui/material/Button';

const snackBarAction = (key) => {
  const reload = () => {
    window.location.reload(false);
  };
  return (
    <Button variant="text" onClick={reload} type="button" key={key}>
      Reload
    </Button>
  );
};
export default snackBarAction;
