import { ArrowBack } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Backdrop, ListItem, ListItemAvatar } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Button from '@mui/material/Button';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { convertToTitleCase, formatToRupiah } from '../../utils';
import {
  BASE_URL_SERVER,
  BUTTONS_AMOUNT_TEMPLATE, ERROR_CODES,
  LOCAL_STORAGE_TOKEN_KEY,
  LOCAL_STORAGE_USER_ID_KEY
} from '../../constant';
import CustomAvatar from '../../Components/CustomAvatar';
import snackBarAction from '../Error/SnackBarAction';

const InputAmountForm = ({
  setPrevious, payee, navigate
}) => {
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '';
  const userId = localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY) || '';
  const [sender, setSender] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleSetPrevious = () => {
    setPrevious();
  };

  const loadUserInformation = useCallback(async () => {
    const response = await axios.get(`${BASE_URL_SERVER}/customers/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const { data } = response;
    setSender(data);
  }, [token, userId]);

  useEffect(() => {
    loadUserInformation();
  }, [loadUserInformation]);

  const _parseInt = (integerStringCurrencyFormat) => {
    const RADIX = 10;
    if (!integerStringCurrencyFormat) {
      return 0;
    }
    return parseInt(integerStringCurrencyFormat.replace(/,/g, ''), RADIX);
  };

  const handleSnackbarMessage = (message) => {
    enqueueSnackbar(message, { autoHideDuration: 2500 });
  };

  const schema = Yup.object().shape({
    amount: Yup.string()
      .required('Amount is required')
      .test(
        'minimumAmountTransfer',
        'Minimum transfer amount is Rp1,000',
        (value) => {
          const MINIMUM_TRANSFER_AMOUNT = 1000;
          const valueInt = _parseInt(value);
          return valueInt >= MINIMUM_TRANSFER_AMOUNT;
        }
      ).test(
        'maximumAmountTransfer',
        'Maximum transfer amount is Rp100,000,000',
        (value) => {
          const MAXIMUM_TRANSFER_AMOUNT = 100000000;
          const valueInt = _parseInt(value);
          return valueInt <= MAXIMUM_TRANSFER_AMOUNT;
        }
      ),
    description: Yup.string().required('Description is required').max(25, 'Maximum 25 characters')
  });

  const {
    register, handleSubmit, setError, formState:
            { errors, isSubmitting }, control, setValue, getValues
  } = useForm({ resolver: yupResolver(schema) });

  const addAmount = (value) => {
    const previousValue = _parseInt(getValues('amount')) || 0;
    const valueToAdd = previousValue + value;
    setValue('amount', valueToAdd.toString(), { shouldValidate: true });
  };

  const renderButtons = () => BUTTONS_AMOUNT_TEMPLATE.map((button) => (
    <Button
      key={button.label}
      variant="outlined"
      sx={{ borderRadius: 20, margin: 0.5 }}
      color="primary"
      onClick={() => addAmount(button.amount)}
    >
      {button.label}
    </Button>
  ));

  const handleSubmitTransfer = async (validatedAmount) => {
    const amount = _parseInt(validatedAmount.amount);
    if (amount > sender.wallet.balance) {
      setError('amount', { message: 'Balance not sufficient' });
      return;
    }
    try {
      const requestBody = {
        customer: {
          cashTag: payee.cashTag
        },
        amount: _parseInt(validatedAmount.amount),
        description: validatedAmount.description
      };
      await axios.post(`${BASE_URL_SERVER}/customers/${userId}/transactions`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setOpenBackdrop(true);
      handleSnackbarMessage('Transfer success! redirecting to transaction');
      setTimeout(() => {
        navigate('/history');
      }, 2500);
    } catch (error) {
      if (error.response.status === ERROR_CODES.UNREACHABLE) {
        enqueueSnackbar('Server unreachable', {
          persist: true,
          action: snackBarAction,
          preventDuplicate: true
        });
        return;
      }
      handleSnackbarMessage('Unable to transfer');
    }
  };
  return (
    <div data-testid="input-amount-form">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      />
      <Button
        startIcon={<ArrowBack />}
        variant="plain"
        color="primary"
        onClick={handleSetPrevious}
      >
        Back
      </Button>
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" fontWeight="700">
          Input Amount
        </Typography>
        <Typography component="span">Enter your transfer amount to :</Typography>
        <ListItem sx={{
          borderRadius: '5px',
          flexWrap: 'wrap',
          bgcolor: 'background.paper',
          my: {
            xs: 1,
            md: 1
          }
        }}
        >
          <ListItemAvatar>
            <CustomAvatar customer={payee} />
          </ListItemAvatar>
          <ListItemText
            sx={{ marginLeft: 2 }}
            primary={(
              <Typography fontWeight={700}>
                {convertToTitleCase(payee?.name)}
              </Typography>
                        )}
            secondary={payee?.cashTag}
          />
        </ListItem>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(handleSubmitTransfer)}
        >
          <Box mt={1} />
          <Typography variant="subtitle2">
            Active balance
            {' '}
            {formatToRupiah(sender?.wallet?.balance)}
          </Typography>
          <Controller
            name="amount"
            variant="outlined"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <NumberFormat
                autoComplete="amount"
                required
                variant="standard"
                fullWidth
                id="amount"
                customInput={TextField}
                thousandSeparator
                name={name}
                value={value}
                onChange={onChange}
                disabled={isSubmitting}
                helperText={errors.amount && errors.amount.message}
                error={!!errors.amount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      Rp
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
          <Box sx={{
            display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', mt: 1
          }}
          >
            {renderButtons()}
          </Box>
          <Box mt={3} />
          <TextField
            autoComplete="amount"
            name="amount"
            size="medium"
            autoFocus
            fullWidth
            label="Description"
            variant="standard"
            multiline
            rows={2}
            {...register('description')}
            helperText={errors.description && errors.description.message}
            error={!!errors.description}
          />
          <Button
            fullWidth
            variant="contained"
            disabled={isSubmitting}
            type="submit"
            sx={{
              mt: 5,
              mb: 2
            }}
          >
            Transfer
          </Button>
        </Box>
      </Box>
      <Box />
    </div>
  );
};
InputAmountForm.defaultProps = {
  payee: null
};
InputAmountForm.propTypes = {
  setPrevious: PropTypes.func.isRequired,
  payee: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    linkPhoto: PropTypes.string,
    cashTag: PropTypes.string.isRequired
  }),
  navigate: PropTypes.func.isRequired
};
export default InputAmountForm;
