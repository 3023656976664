import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { transactionPropTypes } from '../../PropTypes';
import TransactionList from '../HistoryTransaction/TransactionList';
import noTransactionIcon from '../../assets/bankrupt.png';

const RecentTransactionsCard = ({ transactions }) => (
  <>
    <Typography variant="subtitle1" component="div" fontWeight={600} mb={1}>
      Recent Transactions
    </Typography>
    { transactions.length === 0 ? (
      <Box sx={{
        width: '100%',
        pt: {
          xs: 2,
          md: 2
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      >
        <img src={noTransactionIcon} style={{ width: 90, height: '100%' }} alt="no transaction" />
        <Typography>No transaction record found</Typography>
      </Box>
    )
      : (<TransactionList historyTransaction={transactions} />)}
  </>
);

RecentTransactionsCard.propTypes = {
  transactions: PropTypes.arrayOf(transactionPropTypes).isRequired
};
export default RecentTransactionsCard;
