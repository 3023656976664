import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import InputAmountForm from './InputAmountForm';
import InputPayeeForm from './InputPayeeForm';

const TransferPage = () => {
  const location = useLocation();
  let initialPayeeState = null;
  let initialNextFormState = false;
  const payeeFromLocationState = location.state?.payee;
  if (payeeFromLocationState) {
    initialPayeeState = payeeFromLocationState;
    initialNextFormState = true;
  }
  const [nextForm, setNextForm] = useState(initialNextFormState);
  const [payee, setPayee] = useState(initialPayeeState);
  const navigate = useNavigate();
  return (
    <>
      <CssBaseline />
      <Container
        maxWidth="sm"
        data-testid="transfer-page"
        sx={{
          pt: {
            xs: 2,
            md: 2
          },
          overflow: 'scroll',
          height: '100vh'
        }}
      >
        {!nextForm ? (
          <InputPayeeForm
            setNext={() => { setNextForm(true); }}
            setPayee={setPayee}
            payee={payee}
          />
        ) : (
          <InputAmountForm
            setPrevious={() => { setNextForm(false); }}
            payee={payee}
            navigate={navigate}
          />
        )}
      </Container>
    </>
  );
};

export default TransferPage;
