import { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { CardMedia } from '@mui/material';
import { BASE_URL_SERVER, LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_ID_KEY } from '../../constant';
import paynowLogo from '../../assets/paynowLogo.png';

const LoginPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const schema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Email is not valid'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Minimum password length is 8 characters')
      .max(25, 'Maximum password length is 25 characters')
  });
  const {
    register, handleSubmit, formState:
            { errors, isSubmitting }
  } = useForm({ resolver: yupResolver(schema) });

  const snackBarAction = (key) => {
    const reload = () => {
      window.location.reload(false);
    };
    return (
      <Button variant="text" onClick={reload} type="button" key={key}>
        Reload
      </Button>
    );
  };

  const handleSubmitWithValidInput = async (loginData) => {
    try {
      const { data } = await axios.post(`${BASE_URL_SERVER}/tokens`, loginData);
      const { token, userId } = data;
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
      localStorage.setItem(LOCAL_STORAGE_USER_ID_KEY, userId);
      navigate('/', { replace: true });
    } catch (error) {
      if ([422, 401, 404].includes(error.response.status)) {
        const message = 'Wrong email or password';
        enqueueSnackbar(message, { persist: true, action: snackBarAction, preventDuplicate: true });
      }
      if (error.response.status === 0) {
        enqueueSnackbar(error.message, {
          persist: true,
          action: snackBarAction,
          preventDuplicate: true
        });
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <CssBaseline />
      <Container
        maxWidth="sm"
        data-testid="login-page"
        sx={{
          pt: {
            xs: 2,
            md: 2
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          overflow: 'scroll'
        }}
      >
        <CardMedia
          component="img"
          sx={{ width: 200, mb: 5 }}
          image={paynowLogo}
          alt="profile-photo"
        />

        <Box component="form" onSubmit={handleSubmit(handleSubmitWithValidInput)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                disabled={isSubmitting}
                {...register('email')}
                helperText={errors.email && errors.email.message}
                error={!!errors.email}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
            >
              <TextField
                variant="outlined"
                margin="dense"
                name="password"
                fullWidth
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                id="password"
                autoComplete="current-password"
                disabled={isSubmitting}
                {...register('password')}
                helperText={errors.password && errors.password.message}
                error={!!errors.password}
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            sx={{
              mt: 3,
              mb: 2
            }}
          >
            Login
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default LoginPage;
