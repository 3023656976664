import { CardMedia, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import { LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_ID_KEY } from '../../constant';
import paynowLogo from '../../assets/paynowLogo.png';

const SettingsPage = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    localStorage.removeItem(LOCAL_STORAGE_USER_ID_KEY);
    navigate('/login', { replace: true });
  };
  const ourTeam = [
    'Brainli',
    'Grace Raissa Kelana',
    'Ilham Tubagus Arfian',
    'Jevan Gazali',
    'Kahfi Del Vieri',
    'Michael Axel Pangestu'
  ];
  return (
    <>
      <CssBaseline />
      <Container
        maxWidth="sm"
        data-testid="settings-page"
        sx={{
          pt: {
            xs: 2,
            md: 2
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          overflow: 'scroll'
        }}
      >
        <Typography display="flex" flexDirection="row" justifyContent="center" variant="h5" style={{ fontWeight: 200 }}>
          About Us
        </Typography>
        <Box sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', my: 3
        }}
        >
          {ourTeam.map((member) => (
            <Typography
              color="primary"
              style={{
                fontSize: 20, fontFamily: 'Roboto', listStyleType: 'none'
              }}
            >
              {member}
            </Typography>
          ))}
        </Box>
        <CardMedia
          component="img"
          sx={{ width: 100, marginBottom: 3 }}
          image={paynowLogo}
          alt="settings-paynow-logo"
        />
        <Typography display="flex" flexDirection="row" justifyContent="center" style={{ fontWeight: 100, color: 'gray' }}>
          version 1.0.0
        </Typography>
        <Button
          variant="contained"
          type="submit"
          sx={{
            mt: 3,
            mb: 2
          }}
          onClick={handleLogout}
          color="error"
        >
          Logout
        </Button>
      </Container>
    </>
  );
};
export default SettingsPage;
