import { Backdrop } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import axios from 'axios';
import { BASE_URL_SERVER, ERROR_CODES } from '../../constant';
import snackBarAction from '../Error/SnackBarAction';
import CheckCashTagForm from '../../Components/CheckCashTagForm';

const AddContactForm = ({
  token,
  userId,
  handleBackToContacts,
  enqueueSnackbar
}) => {
  const [payee, setPayee] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [errorOnSubmit, setErrorOnSubmit] = useState(false);
  const onSubmitContact = async () => {
    if (payee?.id.toString() === userId) {
      setErrorOnSubmit(true);
      return;
    }
    try {
      await axios.post(`${BASE_URL_SERVER}/customers/${userId}/contacts`, { cashTag: payee.cashTag }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setOpenBackdrop(true);
      enqueueSnackbar('Add new contact success!', { autoHideDuration: 2500 });
      setTimeout(() => {
        handleBackToContacts();
      }, 2500);
    } catch (error) {
      setOpenBackdrop(false);
      setPayee(null);
      if (error.response.status === ERROR_CODES.UNREACHABLE) {
        enqueueSnackbar('Server unreachable', {
          persist: true,
          action: snackBarAction,
          preventDuplicate: true
        });
        return;
      }
      enqueueSnackbar('Unable add new contact', { autoHideDuration: 2500 });
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (muiTheme) => muiTheme.zIndex.drawer + 1 }}
        open={openBackdrop}
      />
      <CheckCashTagForm
        handleBack={handleBackToContacts}
        enqueueSnackbar={enqueueSnackbar}
        setPayee={setPayee}
        payee={payee}
        onSubmit={onSubmitContact}
        dataTestId="add-contact-form"
        wording={{
          title: ' Add New Contact',
          subTitle: 'Save your friends contact so you can easily transfer money to them',
          customerNotFound: 'Contact not found make sure the',
          submitButton: 'Add',
          errorValidateSubmitMessage: 'Couldn\'t add yourself as a contact'
        }}
        userId={userId}
        token={token}
        errorOnValidateSubmit={errorOnSubmit}
      />
    </>
  );
};
AddContactForm.propTypes = {
  userId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  handleBackToContacts: PropTypes.func.isRequired
};
export default AddContactForm;
