import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FilterListIcon from '@mui/icons-material/FilterList';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormHelperText, InputAdornment, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';

const HistoryAppBar = (props) => {
  const RADIX = 10;
  const MAXIMUM_KEYWORD = 25;
  const MINIMUM_AMOUNT = 1000;
  const MAXIMUM_AMOUNT = 100000000;
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [minimumAmount, setMinimumAmount] = useState(MINIMUM_AMOUNT);
  const [maximumAmount, setMaximumAmount] = useState(MAXIMUM_AMOUNT);
  const MINIMUM_AMOUNT_ERROR = 'Minimum amount can not be less than Rp1,000.00';
  const MAXIMUM_AMOUNT_ERROR = 'Maximum amount can not be greater than Rp100,000,000.00';
  const MINIMUM_AMOUNT_HIGHER_THAN_MAXIMUM_ERROR = 'Maximum amount must be greater than or equals to minimum amount';
  const [open, setOpen] = useState(false);
  const {
    handleChangeSort, sort, order, handleChangeOrder, loadHistoryTransaction
  } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    if (value.length <= MAXIMUM_KEYWORD) {
      setSearch(value);
    }
  };

  const handleMinimumAmountChange = (event) => {
    const { value } = event.target;
    setMinimumAmount(parseInt(value, RADIX));
  };

  const handleMaximumAmountChange = (event) => {
    const { value } = event.target;
    setMaximumAmount(parseInt(value, RADIX));
  };

  const validateAmount = (amountField) => {
    if (amountField < MINIMUM_AMOUNT) {
      return true;
    }
    if (amountField > MAXIMUM_AMOUNT) {
      return true;
    }
    return minimumAmount > maximumAmount;
  };

  const handleApplyButton = () => {
    if (validateAmount(minimumAmount) || validateAmount(maximumAmount)) {
      return;
    }
    loadHistoryTransaction(search.trim(), minimumAmount, maximumAmount);
    setOpen(false);
  };

  const getAmountErrorMessage = () => {
    if (minimumAmount > maximumAmount) {
      return MINIMUM_AMOUNT_HIGHER_THAN_MAXIMUM_ERROR;
    }
    if (minimumAmount < MINIMUM_AMOUNT) {
      return MINIMUM_AMOUNT_ERROR;
    }
    if (maximumAmount > MAXIMUM_AMOUNT) {
      return MAXIMUM_AMOUNT_ERROR;
    }
    return '';
  };

  return (
    <Box sx={{ flexGrow: 1 }} data-testid="history-app-bar">
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button onClick={handleOpenDialog} endIcon={<FilterListIcon />} sx={{ textTransform: 'none' }}>
          Filter
        </Button>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
        data-testid="dialog-sort-order"
      >
        <DialogTitle id="responsive-dialog-title-filter">
          Filter
        </DialogTitle>
        <DialogContent />
        <FormControl fullWidth size="small">
          <TextField
            data-testid="description-search-bar"
            id="outlined-basic"
            label="Description"
            variant="outlined"
            inputProps={{ 'aria-label': 'search' }}
            sx={{ mx: 3 }}
            value={search}
            onChange={handleChangeSearch}
          />
        </FormControl>
        <FormControl fullWidth size="small" style={{ display: 'flex', flexDirection: 'row' }}>
          <NumberFormat
            data-testid="minimum-amount-search-bar"
            autoComplete="amount"
            required
            variant="outlined"
            fullWidth
            id="minimum-amount"
            customInput={TextField}
            sx={{ ml: 3, mr: 2, mt: 3 }}
            thousandSeparator
            value={minimumAmount}
            error={validateAmount(minimumAmount)}
            onValueChange={(values) => {
              handleMinimumAmountChange({
                target: {
                  value: values.value
                }
              });
            }}
            label="Minimum"
            min={MINIMUM_AMOUNT}
            max={MAXIMUM_AMOUNT}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  Rp
                </InputAdornment>
              )
            }}
          />
          <NumberFormat
            data-testid="maximum-amount-search-bar"
            autoComplete="amount"
            required
            variant="outlined"
            fullWidth
            id="maximum-amount"
            customInput={TextField}
            sx={{ mr: 3, ml: 2, mt: 3 }}
            thousandSeparator
            value={maximumAmount}
            error={validateAmount(maximumAmount)}
            onValueChange={(values) => {
              handleMaximumAmountChange({
                target: {
                  value: values.value
                }
              });
            }}
            label="Maximum"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  Rp
                </InputAdornment>
              )
            }}
          />
        </FormControl>
        <FormHelperText
          sx={{ mx: 3 }}
          error={validateAmount(minimumAmount) || validateAmount(maximumAmount)}
        >
          {getAmountErrorMessage()}
        </FormHelperText>
        <DialogTitle id="responsive-dialog-title-sort-order">
          Sort & Order
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth size="small" sx={{ my: 2 }} data-testid="select-sort">
            <InputLabel id="select-sort">Sort</InputLabel>
            <Select
              labelId="select-sort"
              id="select-sort"
              value={sort}
              label="Sort"
              data-testid="select-sort-wrapper"
              onChange={handleChangeSort}
            >
              <MenuItem value="DATE">Date</MenuItem>
              <MenuItem value="AMOUNT">Amount</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" sx={{ my: 2 }} data-testid="select-order">
            <InputLabel id="select-order">Order</InputLabel>
            <Select
              labelId="select-order"
              id="select-order"
              value={order}
              label="Order"
              onChange={handleChangeOrder}
            >
              <MenuItem value="ASCENDING">Ascending</MenuItem>
              <MenuItem value="DESCENDING">Descending</MenuItem>
            </Select>
          </FormControl>
          <DialogContentText>
            Sort by date, description, or amount of transaction in ascending or descending. If sort
            order not selected descending sort will be use automatically.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleApplyButton} autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
HistoryAppBar.propTypes = {
  handleChangeSort: PropTypes.func.isRequired,
  handleChangeOrder: PropTypes.func.isRequired,
  loadHistoryTransaction: PropTypes.func,
  sort: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired
};

HistoryAppBar.defaultProps = {
  loadHistoryTransaction: null
};
export default HistoryAppBar;
