import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { useEffect, useState, useCallback } from 'react';
import { Button } from '@mui/material';
import HistoryAppBar from '../../Components/HistoryAppBar';
import { BASE_URL_SERVER, LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_ID_KEY } from '../../constant';
import TransactionList from './TransactionList';
import noTransactionIcon from '../../assets/bankrupt.png';

const HistoryTransactionPage = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '';
  const userId = localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY) || '';
  const [sort, setSort] = useState('DATE');
  const [order, setOrder] = useState('DESCENDING');
  const [historyTransaction, setHistoryTransaction] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const snackBarAction = (key) => {
    const reload = () => {
      window.location.reload(false);
    };
    return (
      <Button variant="text" onClick={reload} type="button" key={key}>
        Reload
      </Button>
    );
  };

  const handleError = useCallback(
    (error) => {
      if ([422, 401, 404].includes(error.response.status)) {
        const message = 'Unable to load transaction history';
        enqueueSnackbar(message, { persist: true, action: snackBarAction, preventDuplicate: true });
      }
      if (error.response.status === 0) {
        enqueueSnackbar(error.message, {
          persist: true,
          action: snackBarAction,
          preventDuplicate: true
        });
      }
    },
    [enqueueSnackbar]
  );

  const handleChangeSort = (event) => {
    const { value } = event.target;
    setSort(value);
  };

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);
  };

  const loadHistoryTransaction = useCallback(async (search, minimumAmount, maximumAmount) => {
    try {
      let query = {
        sortBy: sort,
        sortOrder: order,
        description: search,
        amountFrom: minimumAmount,
        amountTo: maximumAmount
      };
      Object.keys(query).forEach((key) => {
        if (query[key] === '') {
          delete query[key];
        }
      });
      query = queryString.stringify(query);
      const { data } = (await axios.get(`${BASE_URL_SERVER}/customers/${userId}/transactions?${query}&pageSize=100`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }));
      setHistoryTransaction(data);
    } catch (error) {
      handleError(error);
    }
  }, [handleError, order, sort, token, userId]);

  useEffect(() => {
    loadHistoryTransaction();
  }, [loadHistoryTransaction]);

  return (
    <>
      <CssBaseline />
      <Container
        maxWidth="sm"
        data-testid="history-transaction-page"
        sx={{
          pt: {
            xs: 2,
            md: 2
          },
          overflow: 'scroll'
        }}
      >
        <HistoryAppBar
          order={order}
          sort={sort}
          handleChangeOrder={handleChangeOrder}
          handleChangeSort={handleChangeSort}
          loadHistoryTransaction={loadHistoryTransaction}
        />
        {historyTransaction.length === 0
          ? (
            <Box sx={{
              width: '100%',
              height: '85vh',
              pt: {
                xs: 2,
                md: 2
              },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            >
              <img src={noTransactionIcon} alt="no transaction" />
              <Typography>No transaction record found</Typography>
            </Box>
          ) : (
            <TransactionList
              historyTransaction={historyTransaction}
              sx={{ marginBottom: 8 }}
            />
          )}
      </Container>
    </>
  );
};
export default HistoryTransactionPage;
