import numeral from 'numeral-es6';
import moment from 'moment';
import voca from 'voca';

const vocaLibrary = voca;

export const formatToRupiah = (number) => `Rp${numeral(number).format('0,0.00')}`;

export const convertToLocalDateFormat = (date) => moment(date).format('dddd, MMMM Do YYYY, h:mm A');

export const convertToTitleCase = (string) => {
  const lowerCased = vocaLibrary.lowerCase(string);
  return voca.titleCase(lowerCased);
};

export const convertToSentenceCase = (string) => vocaLibrary.capitalize(string, true);
