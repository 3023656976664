import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { Button, CardMedia } from '@mui/material';
import WalletCard from '../../Components/WalletCard';
import {
  BASE_URL_SERVER, DEFAULT_PROFILE, LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_ID_KEY
} from '../../constant';
import RecentTransactionsCard from './RecentTransactionsCard';
import paynowLogo from '../../assets/paynowLogo.png';

const HomePage = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '';
  const userId = localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY) || '';
  const { enqueueSnackbar } = useSnackbar();
  const [profile, setProfile] = useState(DEFAULT_PROFILE);
  const [historyTransaction, setHistoryTransaction] = useState([]);

  const snackBarAction = (key) => {
    const reload = () => {
      window.location.reload(false);
    };
    return (
      <Button variant="text" onClick={reload} type="button" key={key}>
        Reload
      </Button>
    );
  };

  const handleError = useCallback(
    (error) => {
      if ([422, 401, 404].includes(error.response.status)) {
        const message = 'Unable to load profile';
        enqueueSnackbar(message, { persist: true, action: snackBarAction, preventDuplicate: true });
      }
      if (error.response.status === 0) {
        enqueueSnackbar(error.message, {
          persist: true,
          action: snackBarAction,
          preventDuplicate: true
        });
      }
    },
    [enqueueSnackbar]
  );

  const loadHistoryTransaction = useCallback(async () => {
    try {
      const { data } = await axios.get(`${BASE_URL_SERVER}/customers/${userId}/transactions`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setHistoryTransaction(data);
    } catch (error) {
      handleError(error);
    }
  }, [handleError, token, userId]);

  const loadUserInfo = useCallback(async () => {
    try {
      const { data } = await axios.get(`${BASE_URL_SERVER}/customers/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setProfile(data);
    } catch (error) {
      handleError(error);
    }
  }, [token, userId, handleError]);

  useEffect(() => {
    loadUserInfo();
    loadHistoryTransaction();
  }, [loadHistoryTransaction, loadUserInfo]);

  return (
    <>
      <CssBaseline />
      <Container maxWidth="sm" data-testid="home-page" sx={{ pb: 8 }}>
        <Grid
          container
          spacing={2}
          sx={{
            pt: {
              xs: 4,
              md: 4
            }
          }}
        >
          <CardMedia
            component="img"
            sx={{ width: 151, marginLeft: 2 }}
            image={paynowLogo}
            alt="home-paynow-logo"
          />
          <Grid item xs={12} md={12}>
            <WalletCard profile={profile} />
          </Grid>
          <Grid item xs={12} md={12}>
            <RecentTransactionsCard transactions={historyTransaction} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HomePage;
