import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_ID_KEY } from '../../constant';
import ContactList from './ContactList';
import AddContactForm from './AddContactForm';

const ContactsPage = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '';
  const userId = localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY) || '';
  const [displayAddContactForm, setDisplayAddContactForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return (
    <>
      <CssBaseline />
      <Container
        maxWidth="sm"
        data-testid="contacts-page"
        sx={{
          pt: {
            xs: 2,
            md: 2
          },
          overflow: 'auto',
          height: '100%'
        }}
      >
        {!displayAddContactForm
          ? (
            <ContactList
              handleShowAddContactForm={() => setDisplayAddContactForm(true)}
              enqueueSnackbar={enqueueSnackbar}
              token={token}
              userId={userId}
              navigate={navigate}
            />
          ) : (
            <AddContactForm
              enqueueSnackbar={enqueueSnackbar}
              userId={userId}
              handleBackToContacts={() => setDisplayAddContactForm(false)}
              token={token}
            />
          )}
      </Container>
    </>
  );
};
export default ContactsPage;
