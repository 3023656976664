import { useEffect, useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import RestoreIcon from '@mui/icons-material/Restore';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import ContactsIcon from '@mui/icons-material/Contacts';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import SettingsIcon from '@mui/icons-material/Settings';

const navigationItems = [
  {
    pathName: '/',
    navigationBarIndex: 0
  },
  {
    pathName: '/transfer',
    navigationBarIndex: 1
  },
  {
    pathName: '/history',
    navigationBarIndex: 2
  },
  {
    pathName: '/contacts',
    navigationBarIndex: 3
  },
  {
    pathName: '/settings',
    navigationBarIndex: 4
  }
];

const getInitialIndexByPathName = (pathname) => {
  const index = navigationItems.findIndex((navigationItem) => navigationItem.pathName === pathname);
  if (index === -1) {
    return 0;
  }
  return navigationItems[index].navigationBarIndex;
};

const getPathNameByIndex = (index) => {
  const indexFound = navigationItems
    .findIndex((navigationItem) => navigationItem.navigationBarIndex === index);
  return navigationItems[indexFound].pathName;
};

const BottomNavigationBar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const navigationBarIndex = getInitialIndexByPathName((pathname));
  const [value, setValue] = useState(navigationBarIndex);

  useEffect(() => {
    const navigationIndex = getInitialIndexByPathName((pathname));
    setValue(navigationIndex);
  }, [pathname]);

  return (
    <Container
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 0
      }}
      data-testid="bottom-navigation-bar"
      maxWidth="sm"
    >
      <Paper
        variant="outlined"
        sx={{
          my: {
            xs: 0,
            md: 2
          }
        }}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            event.preventDefault();
            setValue(newValue);
            const navigationPath = getPathNameByIndex(newValue);
            navigate(navigationPath);
          }}
          sx={{ borderRadius: '5px' }}
        >
          <BottomNavigationAction label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction label="Transfer" icon={<CompareArrowsIcon />} />
          <BottomNavigationAction label="History" icon={<RestoreIcon />} />
          <BottomNavigationAction label="Contacts" icon={<ContactsIcon />} />
          <BottomNavigationAction label="Settings" icon={<SettingsIcon />} />
        </BottomNavigation>
      </Paper>
    </Container>
  );
};

export default BottomNavigationBar;
