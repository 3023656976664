import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_ID_KEY } from '../../constant';
import { customerPropTypes } from '../../PropTypes';
import CheckCashTagForm from '../../Components/CheckCashTagForm';

const InputPayeeForm = ({ setNext, payee, setPayee }) => {
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '';
  const userId = localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY) || '';
  const [errorOnSubmit, setErrorOnSubmit] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleToNextForm = async () => {
    if (payee?.id.toString() === userId) {
      setErrorOnSubmit(true);
      return;
    }
    setNext();
  };

  return (
    <CheckCashTagForm
      enqueueSnackbar={enqueueSnackbar}
      setPayee={setPayee}
      onSubmit={handleToNextForm}
      payee={payee}
      dataTestId="input-payee-form"
      errorOnValidateSubmit={errorOnSubmit}
      wording={{
        title: 'Input Recipient',
        subTitle: 'To whom do you want to send money?',
        errorValidateSubmitMessage: 'Couldn\'t transfer to yourself',
        submitButton: 'Next',
        customerNotFound: 'Recipient not found make sure the'
      }}
      userId={userId}
      token={token}
    />
  );
};
InputPayeeForm.defaultProps = {
  payee: null
};
InputPayeeForm.propTypes = {
  setNext: PropTypes.func.isRequired,
  payee: customerPropTypes,
  setPayee: PropTypes.func.isRequired
};
export default InputPayeeForm;
