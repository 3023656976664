export const BASE_URL_SERVER = 'https://paynow-api.herokuapp.com';
export const USER_ID = 1;
export const LOCAL_STORAGE_USER_ID_KEY = 'userId';
export const LOCAL_STORAGE_TOKEN_KEY = 'token';

export const DEFAULT_WALLET = {
  id: 0, balance: 0
};
export const DEFAULT_PROFILE = {
  id: 0,
  name: '',
  cashTag: '$',
  email: '',
  wallet: DEFAULT_WALLET
};
export const BUTTONS_AMOUNT_TEMPLATE = [{
  amount: 10000,
  label: '+10,000'
},
{
  amount: 50000,
  label: '+50,000'
},
{
  amount: 100000,
  label: '+100,000'
},
{
  amount: 500000,
  label: '+500,000'
}];

export const ERROR_CODES = {
  NOT_FOUND: 404,
  UNREACHABLE: 0,
  INTERNAL_SERVER_ERROR: 500
};
