import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import queryString from 'query-string';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Alert, AlertTitle, ListItem, ListItemAvatar
} from '@mui/material';
import Button from '@mui/material/Button';
import { ArrowBack } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import snackBarAction from '../Pages/Error/SnackBarAction';
import { BASE_URL_SERVER, ERROR_CODES } from '../constant';
import { customerPropTypes } from '../PropTypes';
import CustomAvatar from './CustomAvatar';
import { convertToTitleCase } from '../utils';

const CheckCashTagForm = ({
  onSubmit,
  payee, setPayee, enqueueSnackbar, token, wording, dataTestId,
  handleBack,
  errorOnValidateSubmit
}) => {
  const theme = useTheme();
  const FIRST_CHARACTER_INDEX = 1;
  let defaultValueCashTag = payee?.cashTag || '';
  defaultValueCashTag = defaultValueCashTag.substring(FIRST_CHARACTER_INDEX);
  const schema = Yup.object().shape({
    cashTag: Yup.string()
      .required('Cash tag is required')
      .matches('^[a-z0-9]*$', 'Cash tag invalid')
  });
  const [errorCustomerNotFound, setErrorCustomerNotFound] = useState(false);
  const {
    register, handleSubmit, setError, formState:
            { errors, isSubmitting }
  } = useForm({ resolver: yupResolver(schema), defaultValues: { cashTag: defaultValueCashTag } });

  const handleCheckCashTag = async (data) => {
    const DOLLAR_SIGN = '$';
    let query = {
      cashTag: `${DOLLAR_SIGN}${data.cashTag}`
    };
    query = queryString.stringify(query);
    try {
      const { data: customer } = await axios.get(`${BASE_URL_SERVER}/customers?${query}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setPayee(customer);
    } catch (error) {
      setPayee(null);
      if (error.response.status === ERROR_CODES.NOT_FOUND) {
        setErrorCustomerNotFound(true);
      }
      if (error.response.status === ERROR_CODES.UNREACHABLE) {
        enqueueSnackbar('Unable to load customer', {
          persist: true,
          action: snackBarAction,
          preventDuplicate: true
        });
      }
    }
  };

  const renderCard = (payeeState, errorCustomerNotFoundState) => {
    if (payeeState) {
      return (
        <ListItem sx={{
          borderRadius: '5px',
          bgcolor: 'background.paper',
          flexWrap: 'wrap',
          my: {
            xs: 1,
            md: 1
          },
          height: '76px'
        }}
        >
          <ListItemAvatar>
            <CustomAvatar customer={payeeState} />
          </ListItemAvatar>
          <ListItemText primary={(
            <Typography fontWeight={700} sx={{ marginLeft: 2 }}>
              {convertToTitleCase(payeeState?.name)}
            </Typography>
                    )}
          />
        </ListItem>
      );
    }
    if (errorCustomerNotFoundState) {
      return (
        <Alert severity="error">
          <AlertTitle>Oops!</AlertTitle>
          {wording.customerNotFound}
          {' '}
          <strong>$CashTag</strong>
          {' '}
          is correct
        </Alert>
      );
    }
    return <Box sx={{ height: 56 }} />;
  };

  useEffect(() => {
    if (errorOnValidateSubmit) {
      setError('cashTag', { message: wording.errorValidateSubmitMessage });
    }
  }, [errorOnValidateSubmit, setError, wording.errorValidateSubmitMessage]);

  return (
    <div data-testid={dataTestId}>
      {
            handleBack && (
            <Button
              startIcon={<ArrowBack />}
              variant="plain"
              color="primary"
              onClick={handleBack}
            >
              Back
            </Button>
            )
        }
      <Box sx={{ my: 10 }}>
        <Typography variant="h5" fontWeight="700">
          {wording.title}
        </Typography>
        <Typography component="span">{wording.subTitle}</Typography>
      </Box>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(handleCheckCashTag)}
        sx={{ height: 65, display: 'flex' }}
      >
        <TextField
          sx={{ width: '70%', height: '100%', marginTop: 0 }}
          autoComplete="cash-tag"
          name="cash-tag"
          required
          variant="standard"
          fullWidth
          id="cash-tag"
          label="Cash Tag"
          autoFocus
          size="small"
          margin="dense"
          disabled={isSubmitting}
          {...register('cashTag', {
            onChange: () => {
              setPayee(null);
              setErrorCustomerNotFound(false);
            }
          })}
          helperText={errors.cashTag && errors.cashTag.message}
          error={!!errors.cashTag}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                $
              </InputAdornment>
            )
          }}
        />
        <Box sx={{
          width: '30%',
          [theme.breakpoints.up('sm')]: {
            marginLeft: 2
          },
          [theme.breakpoints.down('sm')]: {
            marginLeft: 3
          }
        }}
        >
          <Button
            sx={{ height: '60%' }}
            fullWidth
            variant="outlined"
            type="submit"
            size="medium"
            disabled={isSubmitting}
          >
            Check
          </Button>
        </Box>
      </Box>
      <Box mt={5} />
      {renderCard(payee, errorCustomerNotFound)}
      <Box mt={5} />
      <Button
        fullWidth
        variant="contained"
        disabled={!payee}
        onClick={onSubmit}
        sx={{
          mt: 3,
          mb: 2
        }}
      >
        {wording.submitButton}
      </Button>
    </div>
  );
};
CheckCashTagForm.defaultProps = {
  payee: null,
  handleBack: undefined
};
CheckCashTagForm.propTypes = {
  userId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  payee: customerPropTypes,
  setPayee: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  wording: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    customerNotFound: PropTypes.string.isRequired,
    submitButton: PropTypes.string.isRequired,
    errorValidateSubmitMessage: PropTypes.string.isRequired
  }).isRequired,
  dataTestId: PropTypes.string.isRequired,
  handleBack: PropTypes.func,
  errorOnValidateSubmit: PropTypes.bool.isRequired
};
export default CheckCashTagForm;
