import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Card from '@mui/material/Card';
import { useState } from 'react';
import { alpha } from '@mui/material';
import { convertToTitleCase, formatToRupiah } from '../utils';
import { customerPropTypes } from '../PropTypes';

const WalletCard = (props) => {
  const { profile } = props;
  const [hiddenBalance, setHiddenBalance] = useState(true);
  const handleBalanceVisibility = () => {
    setHiddenBalance(!hiddenBalance);
  };

  return (
    <Card
      data-testid="wallet-card"
      sx={{
        position: 'relative',
        borderRadius: 5,
        padding: 5,
        minHeight: 200,
        backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
        minWidth: 300
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
      >
        <Typography
          variant="overline"
          sx={{ fontSize: 20, lineHeight: 3 }}
        >
          {convertToTitleCase(profile.name)}
        </Typography>
        <Typography
          variant="overline"
          sx={{
            fontSize: 15, textTransform: 'none', lineHeight: 0, color: 'gray'
          }}
        >
          {profile.cashTag}
        </Typography>
        <Box sx={{
          display: 'flex', flexDirection: 'row', pt: 5, alignItems: 'center'
        }}
        >
          <IconButton aria-label="toggle-balance" onClick={handleBalanceVisibility}>
            {hiddenBalance ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
          <Typography variant="h5" component="div" style={{ color: 'grey' }}>
            {hiddenBalance ? '●●●●●●' : formatToRupiah(profile.wallet.balance)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        transform: 'translate(70%, 50%)',
        borderRadius: '50%',
        backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.8),
        padding: '40%',
        '&:before': {
          position: 'absolute',
          borderRadius: '50%',
          content: '""',
          display: 'block',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: '-16%',
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1)
        }
      }}
      />
    </Card>
  );
};
WalletCard.propTypes = {
  profile: customerPropTypes.isRequired
};
export default WalletCard;
