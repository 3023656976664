import Box from '@mui/material/Box';
import { blueGrey, deepOrange, green } from '@mui/material/colors';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { transactionPropTypes } from '../../PropTypes';
import {
  convertToLocalDateFormat, convertToSentenceCase, convertToTitleCase, formatToRupiah
} from '../../utils';

const Transaction = ({ transaction }) => {
  const theme = useTheme();
  const TRANSACTION_OUT = 'OUT';

  return (
    <ListItem
      sx={{
        borderRadius: '5px',
        bgcolor: 'background.paper',
        flexWrap: 'wrap',
        my: {
          xs: 1,
          md: 1
        }
      }}
    >
      <ListItemText
        primary={(
          <Typography fontWeight={700}>
            {convertToTitleCase(transaction.participant?.name)}
          </Typography>
        )}
        secondary={(
          <>
            <Typography component="span">
              {convertToSentenceCase(transaction?.description)}
            </Typography>
            <br />
            <Typography color={blueGrey[300]} component="span">
              {convertToLocalDateFormat(transaction?.createdAt)}
            </Typography>
          </>
        )}
      />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        [theme.breakpoints.up('sm')]: {
          alignItems: 'flex-end'
        }
      }}
      >
        <Typography color="primary">
          {transaction.participant?.cashTag}
        </Typography>
        {transaction.transactionType === TRANSACTION_OUT ? (
          <Typography color={deepOrange[500]} fontWeight={500} component="span">
            -
            {' '}
            {formatToRupiah(transaction?.amount)}
          </Typography>
        ) : (
          <Typography color={green[500]} fontWeight={500} component="span">
            +
            {' '}
            {formatToRupiah(transaction?.amount)}
          </Typography>
        )}
      </Box>
    </ListItem>
  );
};

Transaction.propTypes = {
  transaction: transactionPropTypes.isRequired
};

export default Transaction;
