import { List } from '@mui/material';
import PropTypes from 'prop-types';
import { transactionPropTypes } from '../../PropTypes';
import Transaction from './Transaction';

const TransactionList = ({ historyTransaction, sx }) => (
  <List sx={{
    width: '100%',
    ...sx
  }}
  >
    {historyTransaction.map((transaction) => (
      <Transaction key={transaction.id} transaction={transaction} />
    ))}
  </List>
);
TransactionList.defaultProps = {
  sx: {
    marginBottom: 2
  }
};

TransactionList.propTypes = {
  historyTransaction: PropTypes.arrayOf(transactionPropTypes).isRequired,
  sx: PropTypes.shape({
    marginBottom: PropTypes.number.isRequired
  })

};

export default TransactionList;
