import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import PropTypes from 'prop-types';
import { ListItemButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { convertToTitleCase } from '../../utils';
import CustomAvatar from '../../Components/CustomAvatar';
import { customerPropTypes } from '../../PropTypes';

const Contact = ({
  contact, navigate
}) => (
  <ListItem
    disablePadding
    sx={{
      borderRadius: '5px',
      bgcolor: 'background.paper',
      flexWrap: 'wrap',
      my: {
        xs: 1,
        md: 1
      }
    }}
    aria-label="contact item"
    onClick={() => navigate('/transfer', { state: { payee: contact } })}
    secondaryAction={
      <ArrowForwardIosIcon color="primary" sx={{ cursor: 'pointer' }} />
    }
  >
    <ListItemButton>
      <ListItemAvatar>
        <CustomAvatar customer={contact} />
      </ListItemAvatar>
      <ListItemText
        primary={(
          <Typography fontWeight={700}>
            {convertToTitleCase(contact.name)}
          </Typography>
                )}
        secondary={(
          <>
            <Typography component="span">
              {contact.cashTag}
            </Typography>
            <br />
          </>
                )}
      />
    </ListItemButton>
  </ListItem>
);
Contact.propTypes = {
  contact: customerPropTypes.isRequired,
  navigate: PropTypes.func.isRequired
};
export default Contact;
