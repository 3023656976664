import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_ID_KEY } from '../constant';

const UnauthenticatedRoute = ({ children }) => {
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '';
  const userId = localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY) || 0;
  if (token !== '' && userId !== 0) {
    return <Navigate to="/" />;
  }
  return children;
};
UnauthenticatedRoute.propTypes = {
  children: PropTypes.node.isRequired
};
export default UnauthenticatedRoute;
