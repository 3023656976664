import PropTypes from 'prop-types';

export const walletPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired
});

export const customerPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  cashTag: PropTypes.string.isRequired,
  linkPhoto: PropTypes.string
});

export const transactionPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  participant: customerPropTypes.isRequired,
  description: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired
});
