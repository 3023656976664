import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as NotFoundIcon } from '../../assets/icons/confusion.svg';

const NotFoundPage = () => (
  <Box
    data-testid="not-found-page"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      justifyContent: 'center'
    }}
  >
    <Box sx={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}
    >
      <NotFoundIcon style={{ width: '250px', height: '250px' }} />
      <Box ml={1} />
      <Typography
        variant="overline"
        sx={{
          fontSize: 20,
          textDecoration: 'bold',
          textTransform: 'none'
        }}
        color="textSecondary"
      >
        Page not found
      </Typography>
    </Box>
  </Box>
);
export default NotFoundPage;
