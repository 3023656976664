import { grey } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { Route, Routes } from 'react-router-dom';
import AuthenticatedRoute from './Components/AuthenticatedRoute';
import TransferPage from './Pages/Transfer/TransferPage';
import HomePage from './Pages/Home/HomePage';
import LoginPage from './Pages/Login/LoginPage';
import UnauthenticatedRoute from './Components/UnauthenticatedRoute';
import BottomNavigationBar from './Components/BottomNavigationBar';
import HistoryTransactionPage from './Pages/HistoryTransaction/HistoryTransactionPage';
import NotFoundPage from './Pages/Error/NotFoundPage';
import SettingsPage from './Pages/Settings/SettingsPage';
import ContactsPage from './Pages/Contact/ContactsPage';

const theme = createTheme({
  palette: {
    background: {
      default: grey[100]
    }
  }
});
const App = () => (
  <SnackbarProvider maxSnack={3}>
    <ThemeProvider theme={theme}>
      <Routes>
        <Route
          path="/"
          element={(
            <AuthenticatedRoute>
              <HomePage />
              <BottomNavigationBar />
            </AuthenticatedRoute>
        )}
        />
        <Route
          path="/login"
          element={(
            <UnauthenticatedRoute>
              <LoginPage />
            </UnauthenticatedRoute>
          )}
        />
        <Route
          path="/history"
          element={(
            <AuthenticatedRoute>
              <HistoryTransactionPage />
              <BottomNavigationBar />
            </AuthenticatedRoute>
            )}
        />
        <Route
          path="/transfer"
          element={(
            <AuthenticatedRoute>
              <TransferPage />
              <BottomNavigationBar />
            </AuthenticatedRoute>
            )}
        />
        <Route
          path="/contacts"
          element={(
            <AuthenticatedRoute>
              <ContactsPage />
              <BottomNavigationBar />
            </AuthenticatedRoute>
              )}
        />
        <Route
          path="/settings"
          element={(
            <AuthenticatedRoute>
              <SettingsPage />
              <BottomNavigationBar />
            </AuthenticatedRoute>
          )}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ThemeProvider>
  </SnackbarProvider>
);

export default App;
