import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';

const CustomAvatar = ({ customer }) => {
  const _stringToColor = (string) => {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  };

  const _stringAvatar = (name) => {
    const SEPARATOR = ' ';
    const splitName = name.split(SEPARATOR);
    const FIRST_INDEX = 0;
    const LAST_INDEX = splitName.length;
    const avatarWord = `${splitName[FIRST_INDEX][FIRST_INDEX]}${splitName[FIRST_INDEX][LAST_INDEX]}`.toUpperCase();
    return {
      sx: {
        bgcolor: _stringToColor(name)
      },
      children: avatarWord
    };
  };
  if (customer.linkPhoto !== null) {
    return (
      <Avatar
        src={customer.linkPhoto}
        alt="payee-avatar"
        sx={{
          py: 0
        }}
      />
    );
  }
  return <Avatar {..._stringAvatar(customer.name)} alt="payee-avatar" />;
};
CustomAvatar.propTypes = {
  customer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    linkPhoto: PropTypes.string
  }).isRequired
};
export default CustomAvatar;
